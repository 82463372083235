import React, { FC } from 'react';
import ThemeTypes from "./ThemeTypes";
import FileSaver from 'file-saver';

interface GeoPdfProps {
    title: string
    image: string
    type: ThemeTypes
    mapstoreID?: number
    pdf?: any
    link?: string
    mimetype?: string
    filename?: string
}

function openInMapstore(id: number | undefined) {
    const URL = "https://mapstore.demo.nordiq-gis.dk/mapstore/#/viewer/openlayers/";
    window.open(URL + id, '_blank');
}

function openUrl(url: string | undefined) {
    window.open(url, '_blank');
}

const GeoPdf: FC<GeoPdfProps> = ({title,
                                     image,
                                     type,
                                     mapstoreID,
                                     pdf,
                                     filename,
                                     mimetype,
                                     link}) => {

    async function save() {
        /*
        let blob = new Blob([pdf], {
            type: mimetype,
        });
         */

        let bblob = await fetch(pdf).then(r => r.blob());

        console.log(bblob, pdf, filename);
        FileSaver.saveAs(bblob, filename);
    }

    return (
      <div className="card">
          <div className="card-content">

              <figure className="image card m-0">
                  <img src={image} alt={title}/>
              </figure>
          </div>
          <footer className="card-footer">
              <p className="card-footer-item">{title}</p>
              <p className="card-footer-item">
                  {type === ThemeTypes.pdf &&
                        <button onClick={save} className="button is-link is-light">↓ Download</button>
                  }
                  {type === ThemeTypes.external &&
                      <button className="button is-link is-light" onClick={() => openUrl(link)}>↗ Tilgå</button>
                  }
                  {type === ThemeTypes.mapstore &&
                      <button className="button is-link is-light" onClick={() => openInMapstore(mapstoreID)}>↗  Mapstore</button>
                  }
              </p>
          </footer>
      </div>
)
};

export default GeoPdf;
