import React from 'react';
import './App.scss';
import GeoPdf from '../GeoPdf/GeoPdf';

import logo from "./2l_sort1.png";

import bevaringsvaerigeBygningerPdf from './pdf/Bevaringsværdige_bygninger.pdf';
import bevaringsvaerigebygninger from './thumbnails/bevaringsvaerdige_matrikler.png';

import boligstoerrelseKarrePdf from './pdf/Boligstoerrelser_karre.pdf';
import boligstoerrelsekarre from './thumbnails/boligstoerrelse_karre.png';

import byensForbindelserPdf from './pdf/Byens_forbindelser.pdf';
import byensforbindelser from './thumbnails/byens_forbindelser.png';

import byfunktionerPdf from './pdf/Byfunktioner.pdf';
import byfunktioner from './thumbnails/byfunktioner.png';

import bygningerAnvendelsePdf from './pdf/Bygninger_anvendelser.pdf';
import bygningeranvendelse from './thumbnails/bygninger_anvendelser.png';

import bygningOpfoerelsesaarPdf from './pdf/Bygning_opfoerelsesaar.pdf';
import bygningopfoerelsesaar from './thumbnails/bygninger_opfoerelsesaar.png';

import bygningerAntalEtagerPdf from './pdf/Bygninger_antal_etager.pdf';
import bygningerantaletager from './thumbnails/bygninger_antal_etager.png';

import yndlingsstederPdf from './pdf/Yndlingssteder.pdf';
import yndlingsteder from './thumbnails/byfunktioner_yndlingsteder.png';

import rikke from './img/Rikke.jpeg';
import Footer from '../Footer/Footer';
import ThemeTypes from "../GeoPdf/ThemeTypes";

import dxf from './thumbnails/dxf.png';
import dxfFile from './data/esbjerg.dxf';

import dxfmodel from './thumbnails/3d-model-dxf.png';
import dxfModelFile from './data/esbjerg_3D.dxf';

import tredemodel from './thumbnails/3dmodel.png';
import skraafoto from './thumbnails/skraafoto.png';

/*
Nye kort per 28 4.
 */

import bebgyggelsesprocent from './pdf/nye-per-27-04-2023/Bebyggelsesprocent.png';
import bebyggelsesprocentPdf from './pdf/nye-per-27-04-2023/Bebyggelsesprocent.pdf';

import ejerforhold from './pdf/nye-per-27-04-2023/Ejerforhold.png';
import ejerforholdPdf from './pdf/nye-per-27-04-2023/Ejerforhold.pdf';

import matrikler from './pdf/nye-per-27-04-2023/Matrikler.png';
import matriklerPdf from './pdf/nye-per-27-04-2023/Matrikler.pdf';

import oversvoemmelse from './pdf/nye-per-27-04-2023/Oversvoemmelse.png';
import oversvoemmelsePdf from './pdf/nye-per-27-04-2023/Oversvoemmelse.pdf';

import jordforurening from './pdf/nye-per-27-04-2023/Jordforurening.png';
import jordforureningPdf from './pdf/nye-per-27-04-2023/Jordforurening.pdf';

import traer from './pdf/nye-per-27-04-2023/Parkdrift-traer.png';
import traerPdf from './pdf/nye-per-27-04-2023/Parkdrift-traer.pdf';

import befaestelse from './pdf/nye-per-27-04-2023/Befaestelser.png';
import befaestelsePdf from './pdf/nye-per-27-04-2023/Befaestelser.pdf';

import vejstatus from './pdf/nye-per-27-04-2023/Vejstatus.png';
import vejstatusPdf from './pdf/nye-per-27-04-2023/Vejstatus.pdf';

import igangvaerendeprojekter from './pdf/nye-per-27-04-2023/Igangvaerende-projekter.png';
import igangvaerendeprojekterPdf from './pdf/nye-per-27-04-2023/Igangvaerende-projekter.pdf';

import lokalplaner from './pdf/nye-per-27-04-2023/Lokalplaner.png';
import lokalplanerPdf from './pdf/nye-per-27-04-2023/Lokalplaner.pdf';

import rammeplaner from './pdf/nye-per-27-04-2023/Rammeplaner-KP22.png';
import rammeplanerPdf from './pdf/nye-per-27-04-2023/Rammeplaner-KP22.pdf';

import offentligtejetjord from './pdf/nye-per-27-04-2023/Offentligt-ejet-jord.png';
import offentligtejetjordPdf from './pdf/nye-per-27-04-2023/Offentligt-ejet-jord.pdf';

function openInMapstore(id: number | undefined) {
    const URL = "https://mapstore.demo.nordiq-gis.dk/mapstore/?localConfig=configs/esbjerg#/viewer/openlayers/";
    window.open(URL + id, '_blank');
}

const MIMETYPE_PDF = "application/pdf;charset=utf-8";

export function App() {
    return (
        <div className="App">
            <header className="App-header">

                <section className="section is-full">
                    <img src={logo} alt="Esbjerg"/>
                </section>

                <section className="section is-full">
                    <div className="is-centered is-mobile">
                        <h1 className="title">
                            Udvikling af Esbjerg midtby og Rørkjær
                        </h1>
                        <h2 className="is-large">
                            Geodata
                        </h2>
                    </div>
                </section>

                <section className="section is-full">
                    <p>
                        I forbindelse med konkurrencen har vi samlet geodata fra adskillige relevante datakilder.
                    </p>
                    <div className="mt-3">
                        Du kan tilgå dem igennem <a href="#section-mapstore">↓ Mapstore</a>, vores interaktive kortportal.
                        <br/>Nogle står også til rådighed som <a href="#section-geopdf">↓ geoPDF'er</a>.
                        <p>geoPDF'er kan både åbnes i f.eks. Adobe Illustrator, men også i QGIS.</p>
                    </div>
                </section>

                <section className="section is-full" id="section-mapstore">

                    <div className="column is-two-thirds">

                        <h2 className="is-centered">Mapstore</h2>

                        <p className="w-3 pb-3">
                            I Mapstore kan du selv lave analyser ved at sammenholde de forskellige datakilder.
                        </p>
                        <p>Der kan ikke downloades data fra Mapstore!</p>
                            <div className="bulma-overlay-mixin-parent" onClick={() => openInMapstore(979)}>
                                <div className="bulma-overlay-mixin">
                                    <h2 className="mapstore-hero-headline">↗↗  Til Mapstore</h2>
                                </div>
                            </div>

                    </div>

                </section>

                <section className="section is-full" id="section-geopdf">

                    <div className="topic">
                        <h3>Modeller</h3>
                        <div className="container mt-3">
                            <GeoPdf title={'Interaktiv 3D-model'}
                                    image={tredemodel} type={ThemeTypes.external}
                                    link={'https://esbjergkom.maps.arcgis.com/home/webscene/viewer.html?layers=3bf4aa7baf844ff0bfc34e85c3ef95d6#'}></GeoPdf>
                            <GeoPdf title={'Interaktiv skråfoto'}
                                    image={skraafoto}
                                    type={ThemeTypes.external}
                                    link={'https://skraafoto.dataforsyningen.dk/?center=465350.14889049926%2C6146790.966432771&orientation=north&item=2021_83_25_2_0030_00000803'}></GeoPdf>
                            <GeoPdf title={'3D-model (dxf)'}
                                    image={dxfmodel}
                                    type={ThemeTypes.pdf}
                                    pdf={dxfModelFile}
                                    filename={"esbjerg-3d.dxf"}
                                    mimetype={"image/x-dxf"}></GeoPdf>
                            <GeoPdf title={'Alle lag (dxf)'}
                                    image={dxf}
                                    type={ThemeTypes.pdf}
                                    pdf={dxfFile}
                                    filename={"esbjerg.dxf"}
                                    mimetype={"image/x-dxf"}></GeoPdf>
                            {/*
                            <GeoPdf title={'Flere temaer'} image={ejendom} type={ThemeTypes.mapstore} mapstoreID={1560}></GeoPdf>
                            */}
                        </div>
                    </div>

                </section>

                <section className="section is-full" id="section-geopdf">

                    <h2>geoPDF filer</h2>

                    <div className="columns">

                        <div className="column is-three-quarters">

                            <p className="section-intro">
                                I en geoPDF er punkter, linjer og polygoner georefereret og placeres geografisk korrekt, når man importerer
                                dem i et program som kan læse denne slags filer - såsom Adobe Illustrator, Adobe PDF, etc.
                            </p>

                            <p className="section-intro">
                                Data foreligger i 7 emner, nogle i vektorform, andre kun som raster. De førstnævnte datasæt kan du
                                downloade enkeltvist her, de sidste er slået sammen per emne. Alle er kombineret i
                                <a href="#section-mapstore">↑ Mapstore</a>
                            </p>

                        </div>

                    </div>

                    <div className="topic">
                        <h3>Ejendom</h3>
                        <div className="container mt-3">
                            <GeoPdf title={'Bebyggelsesprocent'}
                                    image={bebgyggelsesprocent}
                                    type={ThemeTypes.pdf}
                                    filename={"Bebyggelsesprocent.pdf"}
                                    mimetype={MIMETYPE_PDF}
                                    pdf={bebyggelsesprocentPdf}></GeoPdf>
                            <GeoPdf title={'Ejerfohold'}
                                    image={ejerforhold}
                                    type={ThemeTypes.pdf}
                                    filename={"Ejerforhold.pdf"}
                                    mimetype={MIMETYPE_PDF}
                                    pdf={ejerforholdPdf}></GeoPdf>
                            <GeoPdf title={'Matrikler/Jordstykker'}
                                    image={matrikler}
                                    type={ThemeTypes.pdf}
                                    filename={"Matrikler.pdf"}
                                    mimetype={MIMETYPE_PDF}
                                    pdf={matriklerPdf}></GeoPdf>
                        </div>
                    </div>

                    <div className="topic">
                        <h3>Natur, Miljø & Klima</h3>
                        <div className="container mt-3">
                            <GeoPdf title={'Oversvømmelse'}
                                    image={oversvoemmelse}
                                    type={ThemeTypes.pdf}
                                    filename={"Oversvoemmelse.pdf"}
                                    mimetype={MIMETYPE_PDF}
                                    pdf={oversvoemmelsePdf}></GeoPdf>
                            <GeoPdf title={'Jordforurening'}
                                    image={jordforurening}
                                    type={ThemeTypes.pdf}
                                    filename={"Jordforurening.pdf"}
                                    mimetype={MIMETYPE_PDF}
                                    pdf={jordforureningPdf}></GeoPdf>
                            <GeoPdf title={'Registrerede Træer'}
                                    image={traer}
                                    type={ThemeTypes.pdf}
                                    filename={"Traeer.pdf"}
                                    mimetype={MIMETYPE_PDF}
                                    pdf={traerPdf}></GeoPdf>
                            <GeoPdf title={'Befæstelse'}
                                    image={befaestelse}
                                    type={ThemeTypes.pdf}
                                    filename={"Befaestelse.pdf"}
                                    mimetype={MIMETYPE_PDF}
                                    pdf={befaestelsePdf}></GeoPdf>
                        </div>
                    </div>

                    <div className="topic">
                        <h3>Mobilitet</h3>
                        <div className="container mt-3">
                            <GeoPdf title={'Vejstatus'}
                                    image={vejstatus}
                                    type={ThemeTypes.pdf}
                                    filename={"Vejstatus.pdf"}
                                    mimetype={MIMETYPE_PDF}
                                    pdf={vejstatusPdf}></GeoPdf>
                        </div>
                    </div>

                    <div className="topic">
                        <h3>Planer og Projekter</h3>
                        <div className="container mt-3">
                            <GeoPdf title={'Igangværende Projekter'}
                                    image={igangvaerendeprojekter}
                                    type={ThemeTypes.pdf}
                                    filename={"IgangvaerendeProjekter.pdf"}
                                    mimetype={MIMETYPE_PDF}
                                    pdf={igangvaerendeprojekterPdf}></GeoPdf>
                            <GeoPdf title={'Lokalplaner'}
                                    image={lokalplaner}
                                    type={ThemeTypes.pdf}
                                    filename={"Lokaplaner.pdf"}
                                    mimetype={MIMETYPE_PDF}
                                    pdf={lokalplanerPdf}></GeoPdf>
                            <GeoPdf title={'Rammeplaner KP22'}
                                    image={rammeplaner}
                                    type={ThemeTypes.pdf}
                                    filename={"RammepolanerKP22.pdf"}
                                    mimetype={MIMETYPE_PDF}
                                    pdf={rammeplanerPdf}></GeoPdf>
                            <GeoPdf title={'Offentligt Ejet Jord'}
                                    image={offentligtejetjord}
                                    type={ThemeTypes.pdf}
                                    filename={"OffentligtEjetJord.pdf"}
                                    mimetype={MIMETYPE_PDF}
                                    pdf={offentligtejetjordPdf}></GeoPdf>
                        </div>
                    </div>


                    <div className="topic">
                        <h3>Byens identitet og karakter</h3>
                        <div className="container mt-3">
                            <GeoPdf title={'Bygninger opførelsesår'}
                                    image={bygningopfoerelsesaar}
                                    type={ThemeTypes.pdf}
                                    filename={"Bygninger_opfoerelsesaar.pdf"}
                                    mimetype={MIMETYPE_PDF}
                                    pdf={bygningOpfoerelsesaarPdf}></GeoPdf>
                            <GeoPdf title={'Bygninger anvendelser'} image={bygningeranvendelse}
                                    type={ThemeTypes.pdf}
                                    filename={"Bygninger_anvendelser.pdf"}
                                    mimetype={MIMETYPE_PDF}
                                    pdf={bygningerAnvendelsePdf}></GeoPdf>
                            {/*
                            <GeoPdf title={'Flere temaer'} image={ejendom} type={ThemeTypes.mapstore} mapstoreID={1560}></GeoPdf>
                            */}
                        </div>
                    </div>

                    <div className="topic">
                        <h3>Byens forbindelser</h3>
                        <div className="container mt-3">
                            <GeoPdf title={'Forbindelser'}
                                    image={byensforbindelser}
                                    type={ThemeTypes.pdf}
                                    filename={"Forbindelser.pdf"}
                                    mimetype={MIMETYPE_PDF}
                                    pdf={byensForbindelserPdf}></GeoPdf>
                            {/*
                            <GeoPdf title={'Flere temaer'} image={byfunktioner} type={ThemeTypes.mapstore}
                                    mapstoreID={MAPSTORE_MAIN_PAGE}></GeoPdf>
                            */}
                        </div>
                    </div>

                    <div className="topic">
                        <h3>Byliv og byrum</h3>
                        <div className="container mt-3">
                            <GeoPdf title={'Byfunktioner'} image={byfunktioner}
                                    type={ThemeTypes.pdf}
                                    pdf={byfunktionerPdf}
                                    filename={"Byfunktioner.pdf"}
                                    mimetype={MIMETYPE_PDF}></GeoPdf>
                            <GeoPdf title={'Yndlingssteder'}
                                    image={yndlingsteder}
                                    type={ThemeTypes.pdf}
                                    filename={"Yndlingssteder.pdf"}
                                    mimetype={MIMETYPE_PDF}
                                    pdf={yndlingsstederPdf}></GeoPdf>
                            {/*
                            <GeoPdf title={'Flere temaer'} image={bygninger}
                                    type={ThemeTypes.mapstore}
                                    mapstoreID={MAPSTORE_MAIN_PAGE}></GeoPdf>
                                    */}
                        </div>
                    </div>

                    <div className="topic">
                        <h3>Byfortætning</h3>
                        <div className="container mt-3">
                            <GeoPdf title={'Bevaringsværdige bygninger'}
                                    image={bevaringsvaerigebygninger}
                                    type={ThemeTypes.pdf}
                                    filename={"Bevaringsvaerdige_bygninger.pdf"}
                                    mimetype={MIMETYPE_PDF}
                                    pdf={bevaringsvaerigeBygningerPdf}></GeoPdf>
                            <GeoPdf title={'Boligstørrelser / karre'}
                                    image={boligstoerrelsekarre}
                                    type={ThemeTypes.pdf}
                                    filename={"Boligstoerrelser_per/karre.pdf"}
                                    mimetype={MIMETYPE_PDF}
                                    pdf={boligstoerrelseKarrePdf}></GeoPdf>
                            <GeoPdf title={'Antal etager'}
                                    image={bygningerantaletager}
                                    type={ThemeTypes.pdf}
                                    filename={"Antal_etager.pdf"}
                                    mimetype={MIMETYPE_PDF}
                                    pdf={bygningerAntalEtagerPdf}></GeoPdf>
                            {/*
                            <GeoPdf title={'Flere Temaer'} image={mobilitet} type={ThemeTypes.mapstore}
                                    mapstoreID={MAPSTORE_MAIN_PAGE}></GeoPdf>
                                    */}
                        </div>
                    </div>


                    {/*
                    <div className="topic">
                        <h3>Parkering</h3>
                        <div className="container mt-3">
                            <GeoPdf title={'11 Temaer'} image={parkering} type={ThemeTypes.mapstore}
                                    mapstoreID={MAPSTORE_MAIN_PAGE}></GeoPdf>
                        </div>
                    </div>
                    */}

                    {/*}
                   <div className="topic">
                        <h3>Natur, miljø og klima</h3>
                        <div className="container mt-3">
                            <GeoPdf title={'6 Temaer'} image={naturMiljoeKlima} type={ThemeTypes.mapstore}
                            mapstoreID={1555}></GeoPdf>
                        </div>
                    </div>
                    */}

                    {/*
                    <div className="topic">
                        <h3>Planer og Projekter</h3>
                        <div className="container mt-3">
                            <GeoPdf title={'Igangværende projekter'} image={igangvaerendeProjekter} type={ThemeTypes.pdf} ></GeoPdf>
                            <GeoPdf title={'3 Temaer'} image={planerOgProjekter} type={ThemeTypes.mapstore}
                                    mapstoreID={1550}
                            />
                        </div>
                    </div>
                    */}

                </section>

                <section className="section is-full contact">

                    <h2>Hjælp</h2>

                    <div className="columns mt-5">
                        <div className="column is-3 is-offset-2">
                        <figure className="image is-128x128">
                            <img className="is-rounded portrait"
                                 src={rikke}
                                 alt={"Rikke Gram-Hansen"}/>
                        </figure>
                        </div>
                        <div className="column">
                            <b>Rikke Gram-Hansen<br/>
                                Konkurrencesekretær</b><br/>
                            Nordiq Group<br/>
                            <a href="mailto:rgh@nordiq-group.dk">rgh@nordiq-group.dk</a><br/>
                            +45 53 60 13 48
                        </div>
                    </div>

                </section>

                <Footer></Footer>
            </header>

        </div>
);
}

// export default App;
