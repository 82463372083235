import React, {FC} from 'react';
import esbjergLogoBlack from '../App/2l_sort1.png';

interface FooterProps {
}

// <div className={styles.Footer}>
const Footer: FC<FooterProps> = () => (
    <footer className="footer">
        <figure className="styles.image esbjerg-logo">
            <img src={esbjergLogoBlack} alt="Esbjerg Kommune"/>
        </figure>
            <div>
                <b>Udvikling af Esbjerg midtby og Rørkjær</b>
            </div>

            <p className="mb-3">
                Program for parallelopdrag er udarbejdet af:
                Esbjerg Kommune & Nordiq Group A/S.
            </p>

            <p className="mb-3">
            Processering af geodata samt indretning af geodatainfrastruktur er gennemført at Nordiq Group A/S // GIS Team.
            </p>
            <h2></h2>

    </footer>
);

export default Footer;
